import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

export const query = graphql`
  query($slug: String!) {
    datoCmsBlog(slug: { eq: $slug }) {
      titulo
      slug
      contenido
      categoriaBlog {
        categoria
      }
      fecha(formatString: "DD/MM/YYYY")
      imagen {
        fluid {
          src
        }
      }
    }
  }
`

const Services = ({ data }) => {
  const blog = data.datoCmsBlog

  return (
    <Layout>
      <SEO title={blog.titulo} />

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          {blog.titulo}
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="w-full lg:w-1/3 rounded-lg p-5">
            <img
              src={blog.imagen.fluid.src}
              alt={blog.titulo}
              className="p-3"
            />

            <div className="metadata pb-5">
              <p className="text-sm text-gray-500 p-3">
                Publicado: {blog.fecha}
              </p>
              <p className="hidden text-sm text-gray-500 p-0 pb-5">
                Categoría: {blog.categoriaBlog.categoria}
              </p>
            </div>
          </div>

          <div
            className="w-full lg:w-2/3 mt-5 lg:mt-0 blogpost texts"
            dangerouslySetInnerHTML={{ __html: blog.contenido }}
          />
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Services
